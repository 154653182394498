.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "Google Sans Medium";
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  text-decoration: none;
  outline: none;
  

  &:hover {
    background-color: #f0f0f0;
    color: #000000;
  }
}

.button.disabled {
  background-color: #cccccc;
  color: #999999;
  cursor: not-allowed;
}
